<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Dashboard
    </p>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  props: {
    msg: String
  }
}
</script>

<style scoped>
</style>
